<template>
  <div class="about">
    <h1>SISSy v0.5.1</h1>
    <h2>Sedan Informations- und Stundenplansystem</h2>
    <img src="@/assets/sissy.png" width="350" />
    <br/>
    <span>2021 by Daniel Spittank</span><br/>
    <span>Lizensiert unter der AGPL 3.0 oder später</span>
    <br/>
    <span>Logo by Anabel Fabis, CC-BY-NC-SA</span>
  </div>
</template>

<script>
export default {
  name: 'AboutScreen',
  props: {}
}
</script>