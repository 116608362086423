<template>
  <div class="ids">
    <AboutDialog ref="ad" />
    <v-app-bar
      app
      color="indigo"
      dark
    >
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        src="@/assets/sissy.png"
        transition="scale-transition"
        width="40"
        @click.stop="$refs.ad.showAbout()"
      />

      <v-spacer></v-spacer>

      <v-tabs>
        <v-tab
          v-for="link in links"
          :key="link"
        >
          {{ link }}
        </v-tab>
      </v-tabs>

    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row dense>

          <v-col
            cols="8"
          >
            <v-sheet
              min-height="70vh"
              rounded="lg"
              elevation="2"
            >
              <Vertretungsplan
                ref="vp"
                vpUrl="/stundenplan/proxy.php?file=G014"
                paUrl="/stundenplan/proxy.php?file=G009"
              />
            </v-sheet>
          </v-col>

          <v-col
            cols="4"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
              elevation="2"
            >
              <Newsreader
                ref="nr"
                :feedUrls="['/stundenplan/proxy.php?file=rsslul', '/stundenplan/proxy.php?file=rsssul']"
              />
            </v-sheet>
<!--
            <v-sheet
              rounded="lg"
              min-height="268"
              elevation="2"
            >

            </v-sheet> -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Newsreader from '@/components/Newsreader'
import Vertretungsplan from '@/components/Vertretungsplan'
import AboutDialog from '@/components/AboutDialog'

export default {
  name: 'InfodisplayLehrer',

  data: () => ({
    links: [
      'Vertretungsplan',
      'Stundenpläne'
    ],
    reloadTime: 600000
  }),

  components: {
    Newsreader,
    Vertretungsplan,
    AboutDialog
  },

  created() {
    setTimeout(this.onTimeout, this.reloadTime)
  },

  methods: {
    onTimeout ()  {
      // Reload-Event einrichten
      this.$refs.nr.reload()
      this.$refs.vp.reload()
      setTimeout(this.onTimeout, this.reloadTime)
    }
  }
}
</script>