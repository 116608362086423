<template>
  <div id="newsreader">
    <v-carousel v-model="newsitem"
      cycle
      interval="10000"
      progress
      progress-color="indigo"
      height="400"
      hide-delimiters
      :show-arrows="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
      >
        <v-card
          flat
          height="100%"
        >
          <v-card-title>{{ item.title }}</v-card-title>
          <v-card-text class="news-text" v-html="item.text"></v-card-text>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <v-toolbar 
      flat
      rounded="lg"
    >
      <v-btn @click="newsitem--" rounded><v-icon>mdi-chevron-left</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="newsitem++" rounded><v-icon>mdi-chevron-right</v-icon></v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'Newsreader',
  data() {
    return {
      items: [],
      newsitem: 0,
    };
  },
  props: {
    feedUrls: Array
  },
  methods: {
    reload() {
      // items zurücksetzen
      this.items = []
      // items laden
      for (var i = 0; i < this.feedUrls.length; i++) {
        fetch(this.feedUrls[i])
          .then(response => response.text())
          .then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
          .then(data => {
            const elements = data.querySelectorAll('item');
            elements.forEach(el => {
              // item bauen
              const item = {
                'title': el.querySelector('title').innerHTML.replace(/<!-*\[CDATA\[([\s\S]*)\]\]>/, '$1'),
                'text': el.querySelector('description').innerHTML.replace(/<!-*\[CDATA[^<]*([\s\S]*)\]\]>/, '$1'), 
                'date': el.querySelector('pubDate').innerHTML,
                'feedUrl': this.feedUrls[i]
              }
              // Links entfernen (TODO: Link-Handler einbauen)
              item.text = item.text.replace(/<a[^>]*href=["']*([^'"]*)[^>]*>([^<]*)<\/a>/g, '$2')
              // item auf Array pushen
              this.items.push(item)
            })
          })
          .catch(error => console.error(error))
      }
    },
  },
  created() {this.reload()}
}
</script>

<style>
  div.news-text {
    height: calc(100% - 6px);
    overflow-y: auto;
  }
</style>