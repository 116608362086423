export class Zeitraum {
  constructor (beginnStunde, beginnMinute, endeStunde, endeMinute) {
    this.beginn = new Date("1970-01-01T00:00:00.000")
    this.ende = new Date("1970-01-01T00:00:00.000")
    this.beginn.setHours(parseInt(beginnStunde))
    this.beginn.setMinutes(parseInt(beginnMinute))
    this.ende.setHours(parseInt(endeStunde))
    this.ende.setMinutes(parseInt(endeMinute))
  }
}

export default class Zeitraster {
  constructor (stunden, pausen) {
    if (typeof stunden !== "undefined" && typeof pausen !== "undefined") {
      this.stunden = stunden
      this.pausen = pausen
    } else {
      this.stunden = [
        new Zeitraum(7,50,8,35),
        new Zeitraum(8,35,9,20),
        new Zeitraum(9,40,10,25),
        new Zeitraum(10,25,11,10),
        new Zeitraum(11,30,12,15),
        new Zeitraum(12,20,13,5),
        new Zeitraum(13,10,13,55),
        new Zeitraum(14,0,14,45),
        new Zeitraum(14,50,15,35),
        new Zeitraum(15,40,16,25),
      ]
      this.pausen = [
        new Zeitraum(9,20,9,40),
        new Zeitraum(11,10,11,30),
        new Zeitraum(12,15,12,20),
        new Zeitraum(13,5,14,0),
        new Zeitraum(14,45,14,50),
        new Zeitraum(15,35,15,40),
      ]
    }
  }

  gibStunden() {
    return this.stunden
  }

  gibPausen() {
    return this.pausen
  }

  gibStunde(zeit) {
    if (typeof zeit === "undefined") zeit = new Date()

    // Zeit normalisieren
    zeit.setDate(1)
    zeit.setMonth(0)
    zeit.setYear(1970)

    var aktuelleStunde = 0
    this.stunden.forEach((stunde, index) => {
      // Zeit überprüfen
      if (stunde.beginn <= zeit && stunde.ende > zeit) {
        aktuelleStunde = index + 1
      }
    })
    return aktuelleStunde
  }

  gibVergangeneStunden(zeit) {
    if (typeof zeit === "undefined") zeit = new Date()

    // Zeit normalisieren
    zeit.setDate(1)
    zeit.setMonth(0)
    zeit.setYear(1970)

    var vergangeneStunden = 0
    this.stunden.forEach((stunde, index) => {
      // Zeit überprüfen
      if (stunde.ende <= zeit) {
        vergangeneStunden = index + 1
      }
    })
    return vergangeneStunden
  }

  gibStundeRestzeit(zeit) {
    if (typeof zeit === "undefined") zeit = new Date()

    // Zeit normalisieren
    zeit.setDate(1)
    zeit.setMonth(0)
    zeit.setYear(1970)

    var restzeit = 0
    this.stunden.forEach(stunde => {
      // Zeit überprüfen
      if (stunde.beginn <= zeit && stunde.ende > zeit) {
        const rzDate = (new Date(stunde.ende - zeit))
        restzeit = rzDate.getUTCHours() * 360000 + rzDate.getMinutes() * 60000 + rzDate.getSeconds() * 1000 + rzDate.getMilliseconds();
      }
    })
    return restzeit 
  }

  gibPauseRestzeit(zeit) {
    if (typeof zeit === 'undefined') zeit = new Date()

    // Zeit normalisieren
    zeit.setDate(1)
    zeit.setMonth(0)
    zeit.setYear(1970)

    var restzeit = 0
    this.pausen.forEach(pause => {
      // Zeit überprüfen
      if (pause.beginn <= zeit && pause.ende > zeit) {
        const rzDate = (new Date(pause.ende - zeit))
        restzeit = rzDate.getUTCHours() * 360000 + rzDate.getMinutes() * 60000 + rzDate.getSeconds() * 1000 + rzDate.getMilliseconds();
      }
    })
    return restzeit 
  }

  setzeStunden(stunden) {
    this.stunden = stunden
  }

  setzePausen(pausen) {
    this.pausen = pausen
  }

  istPause(zeit) {
    if (typeof zeit === "undefined") zeit = new Date()

    // Zeit normalisieren
    zeit.setDate(1)
    zeit.setMonth(0)
    zeit.setYear(1970)

    var jetztPause = false
    this.pausen.forEach(pause => {
      // Zeit überprüfen
      if (pause.beginn <= zeit && pause.ende > zeit) jetztPause = true
    })
    return jetztPause
  }

}
  