<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-card>
      <v-card-title class="text-h5">
        Über SISSy
      </v-card-title>

      <v-card-text>
        <AboutScreen/>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="indigo darken-1"
          text
          @click="dialog = false"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AboutScreen from '@/components/AboutScreen'

export default {
  name: 'AboutDialog',
  components: {
    AboutScreen
  },
  data: () => {
    return {
      dialog: false
    }
  },
  methods: {
    showAbout () {
      this.dialog = true
    }
  }
}
</script>