var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"vertretungsplan"}},[_c('v-data-table',{staticClass:"overflow-y-auto",attrs:{"headers":_vm.headers,"fixed-header":true,"height":"85vh","items":_vm.vp,"items-per-page":10000,"item-key":"nr","sort-by":"stunde","group-by":"lehrkraft_vertretung","flat":"","hide-default-footer":"","expanded":_vm.expanded,"single-expand":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pa-2 align-self-start"},[_vm._v("Vertretungsplan für den ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-2 align-self-baseline",class:(_vm.today) ? '' : 'red',attrs:{"rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":(_vm.today) ? '' : 'white'}},[_vm._v("mdi-calendar")]),_c('span',{class:(_vm.today) ? '' : 'white--text'},[_vm._v(_vm._s(_vm.vpDatum.getDate())+"."+_vm._s(_vm.vpDatum.getMonth() + 1)+"."+_vm._s(_vm.vpDatum.getFullYear()))])],1)]}}]),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","allowedDates":_vm.allowedDates,"show-week":true,"locale":"de-DE"},on:{"input":function($event){_vm.datepicker = false}},model:{value:(_vm.neuesDatum),callback:function ($$v) {_vm.neuesDatum=$$v},expression:"neuesDatum"}})],1),(_vm.today)?_c('v-checkbox',{staticClass:"align-self-baseline pa-2",attrs:{"label":"vergangene Vertretungen ausblenden"},model:{value:(_vm.vergangeneAusblenden),callback:function ($$v) {_vm.vergangeneAusblenden=$$v},expression:"vergangeneAusblenden"}}):_vm._e(),_c('v-spacer')],1)]},proxy:true},{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[(isOpen)?_c('v-btn',{on:{"click":toggle}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-account")]),_vm._v(_vm._s(group))],1):_vm._e(),(!isOpen)?_c('v-btn',{on:{"click":toggle}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-account-outline")]),_vm._v(_vm._s(group))],1):_vm._e()],1)]}},{key:"item.datum",fn:function(){return undefined},proxy:true},{key:"header.datum",fn:function(){return undefined},proxy:true},{key:"item.stunde",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":"yellow","dot":"","value":item.letzte_aenderung > _vm.vpDatumsgrenze}},[_vm._v(" "+_vm._s(item.stunde)+" ")])]}},{key:"item.klassen",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.klassen(item.klassen)),function(k,i){return _c('Klasse',{key:i,attrs:{"id":k}})})}},{key:"item.art_vertretung",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vertretungsart(item.art_vertretung))+" ")]}},{key:"item.raum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.art_vertretung !== 'B') ? item.raum : _vm.paOrt(_vm.vpDatum.getDay(), item.stunde, item.lehrkraft))+" ")]}},{key:"item.raum_vertretung",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.art_vertretung !== 'B') ? item.raum_vertretung : _vm.paOrt(_vm.vpDatum.getDay(), item.stunde, item.lehrkraft))+" ")]}},{key:"item.expand",fn:function(ref){
var item = ref.item;
return [(item.anmerkung !== '' && !_vm.expanded.includes(item))?_c('v-btn',{on:{"click":function($event){_vm.expanded = [item]}}},[_c('v-icon',[_vm._v("mdi-comment-plus")])],1):_vm._e(),(item.anmerkung !== '' && _vm.expanded.includes(item))?_c('v-btn',{on:{"click":function($event){_vm.expanded = []}}},[_c('v-icon',[_vm._v("mdi-comment-minus")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.anmerkung !== '')?_c('td',{attrs:{"colspan":headers.length}},[_c('pre',[_vm._v(_vm._s(item.anmerkung))])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }