<template>
  <span>
    <v-chip
      :class="cssClass"
      v-if="id !== '' && typ === 'block'"
    >
      {{ (oberstufe || zug === 'SE') ? '' : stufe }}{{ zug }}
    </v-chip>
    <v-chip
      :class="cssClass"
      v-if="id !== '' && typ !== 'block'"
    >
      {{ (oberstufe || zug === 'SE') ? '' : stufe }}{{ zug }}
    </v-chip>
  </span>
</template>

<script>
export default {
  name: 'Klasse',
  props: {
    id: String,
    typ: String
  },
  computed: {
    stufe () {
      if (this.id === 'Q2') {
        return 13
      } else if (this.id === 'Q1') {
        return 12
      } else if (this.id === 'EF') {
        return 11
      } else {
        return parseInt(this.id)
      }
    },

    zug () {
      if (this.id === 'Q2') {
        return this.id
      } else if (this.id === 'Q1') {
        return this.id
      } else if (this.id === 'EF') {
        return this.id
      } else {
        return this.id.replace(/\d*(\w)/, '$1')
      }
    },

    oberstufe () {
      return this.stufe > 10
    },

    color () {
      var clr = ''

      if (this.stufe === 13) {
        clr = 'purple lighten-3'
      } else if (this.stufe === 12) {
        clr = 'purple lighten-2'
      } else if (this.stufe === 11) {
        clr = 'purple lighten-1'
      } else if (this.stufe === 10) {
        clr = 'pink '
      } else if (this.stufe === 9) {
        clr = 'blue '
      } else if (this.stufe === 8) {
        clr =  'green '
      } else if (this.stufe === 7) {
        clr = 'amber '
      } else if (this.stufe === 6) {
        clr = 'deep-orange '
      } else if (this.stufe === 5) {
        clr = 'red '
      }

      return clr
    },

    cssClass () {
      var cls = this.color
      
      if (this.zug === 'A') {
        cls =  cls + ' lighten-1'
      } else if (this.zug === 'B') {
        cls =  cls + ' lighten-2'
      } else if (this.zug === 'C') {
        cls =  cls + ' lighten-3'
      } else if (this.zug === 'D') {
        cls =  cls + ' lighten-4'
      }

      return cls
    },
  }
}
</script>